import React from "react";
import "./gallery.css";
import agra1 from "./../assets/img/agra-1.jpg";
import amer from "./../assets/img/amer.jpg";
import amerfort from "./../assets/img/amer-fort.jpg";
import vishwanath from "./../assets/img/vishwanath.jpg";
import waterfall from "./../assets/img/waterfall.jpg";
import pandavfall1 from "./../assets/img/pandav-fall-1.jpg";
import taj5 from "./../assets/img/taj-5.jfif";
import varanas1 from "./../assets/img/varanas-1.jpg";
import varanas2 from "./../assets/img/varanas-2.jpg";
import varanas3 from "./../assets/img/varanas-3.jpg";
import photos from "./../assets/img/photos.jpg";
import patwanhaveli from "./../assets/img/patwan-haveli.jpg";
import pic from "./../assets/img/pic.jpg";
import pic2 from "./../assets/img/pic-2.jpg";

import pratapesawr from "./../assets/img/pratapesawr.jpg";
import pandavfall2 from "./../assets/img/pandav-fall-2.jpg";
import panna1 from "./../assets/img/panna-1.jpg";
import pratapswartemple from "./../assets/img/pratapswar-temple.jpg";
import ranehfall from "./../assets/img/raneh-fall.jpg";
import sadhu2 from "./../assets/img/sadhu-2.jpg";
import sanchi1 from "./../assets/img/sanchi-1.jpg";
import sanchi2 from "./../assets/img/sanchi-2.jpg";
import sonarqila from "./../assets/img/sonar-qila.jpg";
import amerelephant from "./../assets/img/amer-elephant.jpg";
import unesco from "./../assets/img/unesco.jpg";

import jahagirmahal from "./../assets/img/jahagir-mahal.jpg";
import jaisalmer from "./../assets/img/jaisalmer.jpg";
import jayvishalpalace1 from "./../assets/img/jayvishal-palace-1.jpg";
import jayvishalpalace2 from "./../assets/img/jayvishal-palace-2.jpg";
import jaswantthada from "./../assets/img/jaswant-thada.jpg";
import jaipur1 from "./../assets/img/jaipur-1.jpg";
import jaipur2 from "./../assets/img/jaipur-2.jpg";
import jaipur3 from "./../assets/img/jaipur-3.jpg";
import jaipur4 from "./../assets/img/jaipur-4.jpg";

import img1 from "./../assets/img/img-1.jpg";
import img2 from "./../assets/img/img-2.jpg";
import img3 from "./../assets/img/img-3.jpg";
import img4 from "./../assets/img/img-4.jpg";
import img5 from "./../assets/img/img-5.jpg";
import img6 from "./../assets/img/img-6.jpg";
import img7 from "./../assets/img/img-7.jpg";
import img8 from "./../assets/img/img-8.jpg";
import img9 from "./../assets/img/img-9.jpg";
import img10 from "./../assets/img/img-10.jpg";
import img11 from "./../assets/img/img-11.jpg";
import img12 from "./../assets/img/img-12.jpg";
import img13 from "./../assets/img/img-13.jpg";
import img14 from "./../assets/img/img-14.jpg";
import img15 from "./../assets/img/img-15.jpg";
import img16 from "./../assets/img/img-16.jpg";
import img17 from "./../assets/img/img-17.jpg";
import img18 from "./../assets/img/img-18.jpg";
import img19 from "./../assets/img/img-19.jpg";
import img20 from "./../assets/img/img-20.jpg";
import img21 from "./../assets/img/img-21.jpg";
import img22 from "./../assets/img/img-22.jpg";
import img23 from "./../assets/img/img-23.jpg";
import img24 from "./../assets/img/img-24.jpg";
import img25 from "./../assets/img/img-25.jpg";
import img26 from "./../assets/img/img-26.jpg";
import img27 from "./../assets/img/img-27.jpg";
import img28 from "./../assets/img/img-28.jpg";
import img29 from "./../assets/img/img-29.jpg";
import img30 from "./../assets/img/img-30.jpg";
import img31 from "./../assets/img/img-31.jpg";
import img32 from "./../assets/img/img-32.jpg";
import img33 from "./../assets/img/img-33.jpg";
import img34 from "./../assets/img/img-34.jpg";
import img35 from "./../assets/img/img-35.jpg";
import img36 from "./../assets/img/img-36.jpg";
import img37 from "./../assets/img/img-37.jpg";
import img38 from "./../assets/img/img-38.jpg";
import img39 from "./../assets/img/img-39.jpg";
import img40 from "./../assets/img/img-40.jpg";
import img41 from "./../assets/img/img-41.jpg";
import img42 from "./../assets/img/img-42.jpg";
import img43 from "./../assets/img/img-43.jpg";
import img44 from "./../assets/img/img-44.jpg";
import img45 from "./../assets/img/img-45.jpg";
import img46 from "./../assets/img/img-46.jpg";
import img47 from "./../assets/img/img-47.jpg";
import img48 from "./../assets/img/img-48.jpg";
import img49 from "./../assets/img/img-49.jpg";
import img50 from "./../assets/img/img-50.jpg";
import img51 from "./../assets/img/img-51.jpg";
import img52 from "./../assets/img/img-52.jpg";
import img53 from "./../assets/img/img-53.jpg";
import img54 from "./../assets/img/img-54.jpg";
import img55 from "./../assets/img/img-55.jpg";
import img56 from "./../assets/img/img-56.jpg";
import img57 from "./../assets/img/img-57.jpg";
import img58 from "./../assets/img/img-58.jpeg";

import khajuraho1 from "./../assets/img/khajuraho-1.jpg";
import khajuraho2 from "./../assets/img/khajuraho-2.jpg";
import khajuraho3 from "./../assets/img/khajuraho-3.jpg";
import khajuraho4 from "./../assets/img/khajuraho-4.jpg";
import khajuraho5 from "./../assets/img/khajuraho-5.jpg";
import khajuraho6 from "./../assets/img/khajuraho-6.jpg";
import khajuraho7 from "./../assets/img/khajuraho-7.jpg";
import khajuraho8 from "./../assets/img/khajuraho-8.jpg";
import khajuraho9 from "./../assets/img/khajuraho-9.jpg";
import khajuraho10 from "./../assets/img/khajuraho-10.jpg";
import khajuraho11 from "./../assets/img/khajuraho-11.jpg";
import khajuraho12 from "./../assets/img/khajuraho-12.jpg";
import khajuraho13 from "./../assets/img/khajuraho-13.jpg";
import khajuraho14 from "./../assets/img/khajuraho-14.jpg";
import khajuraho15 from "./../assets/img/khajuraho-15.jpg";
import khajuraho16 from "./../assets/img/khajuraho-16.jpg";
import khajuraho17 from "./../assets/img/khajuraho-17.jpg";
import khajuraho18 from "./../assets/img/khajuraho-18.jpg";
import khajuraho19 from "./../assets/img/khajuraho-19.jpg";
import khajuraho20 from "./../assets/img/khajuraho-20.jpg";
import khajuraho21 from "./../assets/img/khajuraho-21.jpg";
import khajuraho22 from "./../assets/img/khajuraho-22.jpg";
import khajuraho23 from "./../assets/img/khajuraho-23.jpg";
// import khajuraho24 from "./../assets/img/khajuraho-24.jpg";
import khajuraho25 from "./../assets/img/khajuraho-25.jpg";
import khajuraho26 from "./../assets/img/khajuraho-26.jpg";
import khajuraho27 from "./../assets/img/khajuraho-27.jpg";
import khajuraho28 from "./../assets/img/khajuraho-28.jpg";
import khajuraho29 from "./../assets/img/khajuraho-29.jpg";
import khajuraho30 from "./../assets/img/khajuraho-30.jpg";
import khajuraho31 from "./../assets/img/khajuraho-31.jpg";
import orchha1 from "./../assets/img/orchha-1.jpg";
import orchha2 from "./../assets/img/orchha-2.jpg";
import orchha3 from "./../assets/img/orchha-3.jpg";
import orchha4 from "./../assets/img/orchha-4.jpg";
import orchha5 from "./../assets/img/orchha-5.jpg";
import orchha6 from "./../assets/img/orchha-6.jpg";
import orchha7 from "./../assets/img/orchha-7.jpg";
import orchha8 from "./../assets/img/orchha-8.jpg";
import orchha9 from "./../assets/img/orchha-9.jpg";
import gadisagar from "./../assets/img/gadi-sagar.jpg";
import gwalior_orchha from "./../assets/img/gwalior_orchha.jpg";
import gwalior1 from "./../assets/img/gwalior-1.jpg";
import gwalior2 from "./../assets/img/gwalior-2.jpg";
import gwalior3 from "./../assets/img/gwalior-3.jpg";
import gwalior4 from "./../assets/img/gwalior-4.jpg";
import gwalior5 from "./../assets/img/gwalior-5.jpg";
import hawamahal1 from "./../assets/img/hawa-mahal-1.jpg";
import hawamahal2 from "./../assets/img/hawa-mahal-2.jpg";
import holi1 from "./../assets/img/holi-1.jpg";
import holi2 from "./../assets/img/holi-2.jpg";
import chhatris_orchha from "./../assets/img/chhatris_orchha.jpg";
import citypalace from "./../assets/img/city-palace.jpg";
import devijagdamba from "./../assets/img/devi-jagdamba.jpg";
import dewari2 from "./../assets/img/dewari-2.jpg";
import elephantride from "./../assets/img/elephant-ride.jpg";
import elephant from "./../assets/img/elephant.jpg";
import elephanta2 from "./../assets/img/elephanta-2.jpg";
import elephganta from "./../assets/img/elephganta.jpg";
import fatehpursikri2 from "./../assets/img/fatehpur-sikri-2.jpg";
import fatehpursikri1 from "./../assets/img/fatehpur-sikri-1.jpg";
import fatehpursikri3 from "./../assets/img/fatehpur-sikri-3.jpg";
import badhwgah from "./../assets/img/badhwgah.jpg";
import mandu from "./../assets/img/mandu.jpeg";
import betwa from "./../assets/img/betwa.jpg";
import bhedaghat from "./../assets/img/bhedaghat.jpg";
import mewari2 from "./../assets/img/mewari-2.jpg";
import bhojpur1 from "./../assets/img/bhojpur-1.jpg";
import bhojpur2 from "./../assets/img/bhojpur-2.jpg";
import brahaspatikund from "./../assets/img/brahaspati-kund.jpg";
import laxman from "./../assets/img/laxman.jpg";
import lepeard from "./../assets/img/lepeard.jpg";

import mewari3 from "./../assets/img/mewari-3.jpg";
import mumbai1 from "./../assets/img/mumbai-1.jpg";
import car1 from "./../assets/img/car-1.jpeg";
import car2 from "./../assets/img/car-2.jpeg";
import car3 from "./../assets/img/car-3.jpeg";
import car4 from "./../assets/img/car-4.jpeg";
import car5 from "./../assets/img/car-5.jpeg";
import car6 from "./../assets/img/car-6.jpeg";
import car7 from "./../assets/img/car-7.jpeg";
import pic1 from "./../assets/img/pic-1.jpeg";
// import pic2 from "./../assets/img/pic-2.jpeg";
import pic3 from "./../assets/img/pic-3.jpeg";
import pic4 from "./../assets/img/pic-4.jpeg";
import pic5 from "./../assets/img/pic-5.jpeg";
import pic6 from "./../assets/img/pic-6.jpeg";
import pic7 from "./../assets/img/pic-7.jpeg";
import pic8 from "./../assets/img/pic-8.jpeg";
import pic9 from "./../assets/img/pic-9.jpeg";
import pic10 from "./../assets/img/pic-10.jpeg";
import pic11 from "./../assets/img/pic-11.jpeg";
import pic12 from "./../assets/img/pic-12.jpeg";
import pic13 from "./../assets/img/pic-13.jpeg";
import pic14 from "./../assets/img/pic-14.jpeg";
import pic15 from "./../assets/img/pic-15.jpeg";
import pic16 from "./../assets/img/pic-16.jpeg";
import pic17 from "./../assets/img/pic-17.jpeg";
import pic18 from "./../assets/img/pic-18.jpeg";
import pic19 from "./../assets/img/pic-19.jpeg";
import pic20 from "./../assets/img/pic-20.jpeg";
import pic21 from "./../assets/img/pic-21.jpeg";
import pic22 from "./../assets/img/pic-22.jpeg";
import pic23 from "./../assets/img/pic-23.jpeg";
import pic24 from "./../assets/img/pic-24.jpeg";
import pic25 from "./../assets/img/pic-25.jpeg";
import pic26 from "./../assets/img/pic-26.jpeg";
import pic27 from "./../assets/img/pic-27.jpeg";
import pic28 from "./../assets/img/pic-28.jpeg";
import pic29 from "./../assets/img/pic-29.jpeg";
import pic30 from "./../assets/img/pic-30.jpeg";
import pic31 from "./../assets/img/pic-31.jpeg";
import pic32 from "./../assets/img/pic-32.jpeg";
import pic33 from "./../assets/img/pic-33.jpeg";
import pic34 from "./../assets/img/pic-34.jpeg";
import pic35 from "./../assets/img/pic-35.jpeg";
import pic36 from "./../assets/img/pic-36.jpeg";
import pic37 from "./../assets/img/pic-37.jpeg";
import pic38 from "./../assets/img/pic-38.jpeg";
import pic39 from "./../assets/img/pic-39.jpeg";
import pic40 from "./../assets/img/pic-40.jpeg";
import pic41 from "./../assets/img/pic-41.jpeg";
import pic42 from "./../assets/img/pic-42.jpeg";
import pic43 from "./../assets/img/pic-43.jpeg";
import pic44 from "./../assets/img/pic-44.jpeg";
import pic45 from "./../assets/img/pic-45.jpeg";
import pic46 from "./../assets/img/pic-46.jpeg";
import pic47 from "./../assets/img/pic-47.jpeg";
import pic48 from "./../assets/img/pic-48.jpeg";
import pic49 from "./../assets/img/pic-49.jpeg";
import pic50 from "./../assets/img/pic-50.jpeg";
import pic51 from "./../assets/img/pic-51.jpeg";
import pic52 from "./../assets/img/pic-52.jpeg";
import pic53 from "./../assets/img/pic-53.jpeg";
import pic54 from "./../assets/img/pic-54.jpeg";
import pic60 from "./../assets/img/pic-60.jpeg";
import pic56 from "./../assets/img/pic-56.jpeg";
import pic59 from "./../assets/img/pic-59.jpeg";
import pic58 from "./../assets/img/pic-58.jpeg";
import bicycle1 from "./../assets/img/bicycle1.jpeg";

import bicycle2 from "./../assets/img/bicycle2.jpeg";
import yoga1 from "./../assets/img/yoga-1.jpeg";
import yoga2 from "./../assets/img/yoga-2.jpeg";
import yoga3 from "./../assets/img/yoga-3.jpeg";
import yoga4 from "./../assets/img/yoga-4.jpg";
import yoga9 from "./../assets/img/yoga-9.jpeg";
import { Helmet } from "react-helmet";

const Gallery = () => {
  return (
    <>
      <Helmet>
        <title>Gallery</title>
        <meta
          name="description"
          content="Memories and experience of our customer"
        />
      </Helmet>
      <div className="py-5 ">
        <div className="container py-4">
          <h2 class="text-uppercase lined mb-4">Memories</h2>

          {/* <hr className="w-50 mx-auto" /> */}

          <div className="row  gx-5">
            {/* 444444444444444444444444444444 */}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={bicycle1}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={yoga2}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={yoga3}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={yoga4}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={yoga9}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic17}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            {/* 444444444444444444444444444444 */}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic18}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic19}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic59}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic60}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic20}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic29}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic30}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic31}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic32}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic33}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic34}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic38}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic39}
                  alt="gwalior "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic40}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic41}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic42}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic43}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic44}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic45}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic46}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic51}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic52}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic53}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic54}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic56}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic47}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic48}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic49}
                  alt="gwalior "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic50}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic58}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic1}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic2}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic3}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic4}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic6}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic7}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic8}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic9}
                  alt="gwalior "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic10}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic11}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic12}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic13}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic14}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic15}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic16}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car1}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car6}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gadisagar}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car7}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gwalior_orchha}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gwalior1}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gwalior2}
                  alt="gwalior "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car3}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gwalior3}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car2}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gwalior4}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={gwalior5}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={hawamahal1}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={hawamahal2}
                  alt="hawa-mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={holi1}
                  alt="holi"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={holi2}
                  alt="holi"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={chhatris_orchha}
                  alt="chhatris_orchha "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={citypalace}
                  alt="city-palace"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={devijagdamba}
                  alt="devi-jagdamba"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={dewari2}
                  alt="dewari "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={elephantride}
                  alt="elephant ride"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={elephant}
                  alt="elephant"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={elephanta2}
                  alt="elephanta"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={elephganta}
                  alt="elephganta"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={fatehpursikri2}
                  alt="fatehpur sikri"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={fatehpursikri1}
                  alt="fatehpur-sikri"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={fatehpursikri3}
                  alt="fatehpur-sikri"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={amerelephant}
                  alt="amer-elephant "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={amerfort}
                  alt="amer-fort"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={badhwgah}
                  alt="badhwgah"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={mandu}
                  alt="mandu"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={betwa}
                  alt="betwa"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={bhedaghat}
                  alt="bhedaghat"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={mewari2}
                  alt="mewari"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={bhojpur1}
                  alt="bhojpur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={bhojpur2}
                  alt="bhojpur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={brahaspatikund}
                  alt="brahaspati-kund"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={laxman}
                  alt="laxman mandir"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car4}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={lepeard}
                  alt="lepeard"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={mandu}
                  alt="mandu"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={mewari2}
                  alt="mewari"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={mewari3}
                  alt="mewari"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={mumbai1}
                  alt="jaipur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha1}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha2}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha3}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha4}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha5}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha6}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha7}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha8}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={orchha9}
                  alt="orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jahagirmahal}
                  alt="Jahagir Mahal"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic21}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic22}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic23}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic24}
                  alt="gwalior "
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic25}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic26}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic27}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic28}
                  alt="gwalior"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jaipur1}
                  alt="jaipur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jaipur2}
                  alt="jaipur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jaipur3}
                  alt="jaipur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jaipur4}
                  alt="jaipur"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jaisalmer}
                  alt="Jaisalmer"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jaswantthada}
                  alt="Jaswant Thada"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jayvishalpalace1}
                  alt="jayvishal palace"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={jayvishalpalace2}
                  alt="jayvishal palace"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho1}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho2}
                  alt="khajurahokhajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho3}
                  alt="khajurahokhajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho4}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho5}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho6}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho7}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho8}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho9}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho10}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho11}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho12}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho13}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho14}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho15}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <imgkhajuraho
                  src={khajuraho16}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho17}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho18}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho19}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho20}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho21}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic35}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic36}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic37}
                  alt="gwalior_orchha"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho22}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho23}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho25}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>{" "}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho26}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho27}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho28}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho29}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho30}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={khajuraho31}
                  alt="khajuraho"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            {/* ------------------------------------- */}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={photos}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={patwanhaveli}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img31}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img32}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img33}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img34}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img35}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img36}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img37}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img38}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img39}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img40}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img41}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img42}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img43}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img44}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img45}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img46}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={bicycle2}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={yoga1}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img47}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={car5}
                  alt="gadi-sagar"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img48}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img49}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img50}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img51}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img52}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img53}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img54}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img55}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img56}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img57}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img58}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pratapesawr}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pandavfall2}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={panna1}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pic2}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={pratapswartemple}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={sadhu2}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={sanchi1}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={sanchi2}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={sonarqila}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={taj5}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={unesco}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img1}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img2}
                  alt=""
                  className="img-fluid gallery_img "
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img3}
                  alt=""
                  className="img-fluid gallery_img "
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img4}
                  alt=""
                  className="img-fluid gallery_img "
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img6}
                  alt=""
                  className="img-fluid gallery_img gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img7}
                  alt=""
                  className="img-fluid gallery_img gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img10}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img11}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img13}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img16}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img17}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img18}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img19}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img20}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img21}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img22}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img23}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img24}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img25}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img26}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img27}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto ">
              <figure>
                <img
                  src={img27}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img28}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img29}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img30}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={agra1}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={amer}
                  alt=""
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            {/* .............................................. */}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={varanas1}
                  alt="varanasi"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={varanas2}
                  alt="varanasi"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={varanas3}
                  alt="varanasi"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={vishwanath}
                  alt="vishwanath"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={waterfall}
                  alt="waterfall"
                  className="img-fluid gallery_img"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            {/* <div className="col-md-4 col-10 mx-auto">
            <figure>
              <img src={amerelephant} alt="" className="img-fluid gallery_img"style={{height:280, width:500}}/>
            </figure>
          </div>
          <div className="col-md-4 col-10 mx-auto">
            <figure>
              <img src={img12} alt="" className="img-fluid"style={{height:280, width:500}}/>
            </figure>
          </div> 
          
          <div className="col-md-4 col-10 mx-auto">
            <figure>
              <img src={img9} alt="" className="img-fluid"style={{height:280, width:500}}/>
            </figure>
          </div>  */}
            {/* <div className="col-md-4 col-10 mx-auto">
            <figure>
              <img src={img5} alt="" className="img-fluid"style={{height:280, width:500}}/>
            </figure>
          </div> 
          <div className="col-md-4 col-10 mx-auto">
            <figure>
              <img src={img14} alt="" className="img-fluid"style={{height:280, width:500}}/>
            </figure>
          </div>
          <div className="col-md-4 col-10 mx-auto">
            <figure>
              <img src={img15} alt="" className="img-fluid"style={{height:280, width:500}}/>
            </figure>
          </div> */}
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img17}
                  alt=""
                  className="img-fluid"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
            <div className="col-md-4 col-10 mx-auto">
              <figure>
                <img
                  src={img18}
                  alt=""
                  className="img-fluid"
                  style={{ height: 280, width: 500 }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
